import RealTimeTranslation from "components/RealTImeTrans Comps/realTimeTranslation";
import React from "react";
import Layout from "components/layout";
import Seo from "components/seo";

export default function index() {
  return (
    <Layout>
      <Seo title="Real-Time Translation" slug="/real-time-translation" />
      <RealTimeTranslation />
    </Layout>
  );
}
