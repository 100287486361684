import React from "react";

function WhyChose() {
  return (
    <div className="bg-midBlue py-20">
      <div className=" w-full px-5 lg:w-9/12 flex-col justify-center mx-auto">
        <div className="md:bg-whyChooseUs flex mx-auto justify-center bg-no-repeat bg-center relative">
          <h2 className="text-[#e6e6e6] text-[55px] pt-12 font-boldest sm:whitespace-nowrap">
            {" "}
            <span className="text-orange">WHY</span> CHOOSE TOMEDES
          </h2>
        </div>
        <p className="text-[18px] w-full font-sans pt-8 text-[#e6e6e6] mx-auto lg:w-[45rem] text-center">
          Accurately break the language barrier with our hassle-free
          professional human translation via WhatsApp.
        </p>
        <div className="bottomCOntent pt-20">
          <h3 className="text-white text-3xl font-semibold">
            <span className="text-orange">PROFESSIONAL&nbsp;</span>
            HUMAN TRANSLATION <br />
            AT YOUR FINGER TIPS
          </h3>
          <div className="border-t border-[#e6e6e6] md:ml-24 pt-6 mt-8">
            <p className="text-[#BEBEBE]">
              Say goodbye to back-and-forth quote requests by email. With our
              effortless <br /> translation solution via WhatsApp, you get a
              perfect translation delivered in <br /> minutes.
            </p>
          </div>
        </div>
        <div className="bottomCOntent pt-20">
          <h3 className="text-white text-3xl font-semibold">
            <span className="text-orange">CONNECT&nbsp;</span>
            WITH ANYONE, ANYTIME
          </h3>
          <div className="border-t border-[#e6e6e6] md:ml-24 pt-6 mt-8">
            <p className="text-[#BEBEBE]">
              Make a perfect first impression. Respond instantly to multilingual
              requests
              <br /> in social media and emails with an expert, mother-tongue
              touch.
            </p>
          </div>
        </div>
        <div className="bottomCOntent pt-20">
          <h3 className="text-white text-3xl font-semibold">
            <span className="text-orange">TRANSLATION&nbsp;</span>
            ON-DEMAND
          </h3>
          <div className="border-t border-[#e6e6e6] md:ml-24 pt-6 mt-8">
            <p className="text-[#BEBEBE]">
              Our network of more than 10,000 professional translators is
              available 24/7
              <br /> at your beck and call, supporting more than 30 languages
              and command of
              <br />
              the terminology of every profession and industry under the sun.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyChose;
