import React from "react";

export default function RealTimeSecond() {
  return (
    <div className="bg-darkBlue py-20">
      <div className="w-8/12 flex-col justify-center mx-auto">
        <h2 className="text-[35px] text-white font-secondary font-bold text-center">
          What is <span className="text-lightBlue">Real Time</span> Translation
        </h2>
        <p className="pt-8 font-opensans text-[#E6E6E6] text-center">
          Real-time translation delivers a finished product within minutes. How
          many? It depends on the length of the content. But you have our
          assurance that the job will be completed by an expert translator,
          perfectly, as fast as is humanly possible. It is the preferred
          solution for the most time-sensitive communications and real-time
          events.
        </p>
      </div>
    </div>
  );
}
