import React from "react";
import WhatLogo from "assets/images/whatsapp_english.svg";

export default function RealTimeBanner() {
  return (
    <div className="bg-midBlue py-16 font-opensans">
      <div className=" w-full  2xl:w-9/12 text-[#e6e6e6] px-5 flex lg:flex-row flex-col justify-center mx-auto">
        <div className="left w-full lg:w-[39%]">
          <h1 className="text-[43px] font-bold">
            Real Time Translation Services
          </h1>
          <p className="text-base leading-loose py-5">
            Never miss a deadline with our on-demand, real-time expert
            translation services. Highest priority, exacting translation
            solutions when you just can’t wait.
          </p>
          <div className="div">
            <a href="https://api.whatsapp.com/send?phone=972722200700" target="_blank">
              <img src={WhatLogo} alt="" className="w-[293px]" />
            </a>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-0 lg:gap-2 xl:gap-0 mt-5 font-opensans text-md pt-2">
            <div className="flex flex-row">
              <img
                className="w-4"
                src="https://tomedes.gumlet.io/frontend/images/location-template/badge.svg"
                alt=""
              />
              <p className="ml-1">1 Year Gurantee |</p>
            </div>
            <div className="flex flex-row">
              <img
                className="w-[22px]"
                src="https://tomedes.gumlet.io/frontend/images/location-template/call-center.svg"
                alt=""
              />
              <p className="ml-1">24/7 Availability |</p>
            </div>
            <div className="flex flex-row">
              <img
                className="w-[22px]"
                src="https://tomedes.gumlet.io/frontend/images/location-template/16-express.svg"
                alt=""
              />
              <p className="ml-1">Fast Delivery</p>
            </div>
          </div>
        </div>
        <div className="right w-full lg:w-[44%] pl-2 lg:pt-0 pt-12 lg:pl-32">
          <h2 className="text-orange text-[35px] font-bold">
            How <span className="text-[#e6e6e6]">It Works?</span>
          </h2>
          <ul className="pt-4 list-disc">
            <li className="pt-6">
              Send your translation request via WhatsApp, along with files for
              translation
            </li>
            <li className="pt-6">
              We will immediately connect you with the professional translator
              best suited to your needs
            </li>
            <li className="pt-6">Your translator will rapidly translate your project</li>
            <li className="pt-6">Delivery via WhatsApp or platform of your choice</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
