import GetInTouch from "components/getInTouch";
import WhatsAppComp from "components/reusableComponents/whatsappComp";
import React from "react";
import RealTimeBanner from "./Banner";
import FaQ from "./faQ";
import RealTimeSecond from "./realTIme";
import UseCaseCard from "./useCase";
import WhyChose from "./whyChose";

export default function RealTimeTranslation() {
  return (
    <>
      <RealTimeBanner />
      <RealTimeSecond />
      <WhyChose />
      <UseCaseCard />
      <WhatsAppComp
        backgroundCol="bg-midBlue"
        fHeadCol="text-orange"
        fHead="Connect "
        secondHead="With a Real Time Translator Now"
        content="Looking for a real time translator? Connect with one now via WhatsApp"
        whatsUp
      />
      <FaQ />
      <GetInTouch backgroundCol="bg-midBlue" />
    </>
  );
}
