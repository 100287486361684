import React from "react";

function UseCaseCard() {
  return (
    <div className="bg-darkBlue py-20">
      <h2 className="text-[35px] text-[#e6e6e6] text-center font-semibold">
        {" "}
        <span className="text-lightBlue">Use</span> Cases
      </h2>
      <div className="flex gap-y-6 px-6 lg:flex-row flex-col w-full lg:w-10/12 2xl:w-8/12 justify-between mx-auto gap-x-8 pt-8">
        <div className="card w-full md:w-[35rem] p-5 border px-12 border-[#E6E6E636] rounded-lg flex-col justify-center items-center mx-auto">
          <img
            src="https://tomedes.gumlet.io/frontend/images/realtime/icon-material-email.svg"
            alt=""
            className="mx-auto pb-2"
          />
          <h3 className="font-bold text-white text-center py-4">Emails</h3>
          <p className="text-center text-[#bebebe] leading-loose">
            Immediately break language barriers and impress clients with
            professionally translated emails.
          </p>
        </div>
        <div className="card w-full md:w-[35rem] p-5 border px-12 border-[#E6E6E636] rounded-lg flex-col justify-center items-center mx-auto">
          <img
            src="https://tomedes.gumlet.io/frontend/images/realtime/network.svg"
            alt=""
            className="mx-auto pb-2"
          />
          <h3 className="font-bold text-white text-center py-4">
            Social Media
          </h3>
          <p className="text-center text-[#bebebe] leading-loose">
            Enable yourself and your colleagues to communicate in 30 languages
            to followers on social media.
          </p>
        </div>
        <div className="card w-full md:w-[35rem] p-5 border px-12 border-[#E6E6E636] rounded-lg flex-col justify-center items-center mx-auto">
          <img
            src="https://tomedes.gumlet.io/frontend/images/realtime/icon-ios-paper.svg"
            alt=""
            className="mx-auto pb-2"
          />
          <h3 className="font-bold text-white text-center py-4">
            Urgent Document Translation
          </h3>
          <p className="text-center text-[#bebebe] leading-loose">
            We can rapidly translate any document for any use, on demand,
            instantly.
          </p>
        </div>
      </div>
    </div>
  );
}

export default UseCaseCard;
