import React from "react";

function FaQ() {
  return (
    <div className="bg-darkBlue py-14">
      <h2 className="text-[35px] font-semibold text-lightBlue text-center">
        FAQs
      </h2>
      <div className="flex md:flex-row flex-col w-full lg:w-9/12 2xl:w-7/12 px-6 justify-between mx-auto items-center gap-x-28">
        <div className="left">
          <h3 className="text-white text-[25px] font-semibold font-secondary py-8">
            How does payment work?
          </h3>
          <p className="text-[#e6e6e6] font-opensans">
            After we get your request, our account managers will be right in
            touch with you to process your payment in any way you wish: via the
            website(credit/debit cards), Paypal, wire transfer, or Payoneer.
          </p>
        </div>
        <div className="right">
          <h3 className="text-white text-[25px] font-semibold font-secondary py-8">
            Do we use machine translations?
          </h3>
          <p className="text-[#e6e6e6] font-opensans">
            No. Rest assured that professional human translators will perform
            your translation. Our network of more than 10,000 translators across
            the globe is on call, ready to fulfill your translation needs.
          </p>
        </div>
      </div>
    </div>
  );
}

export default FaQ;
